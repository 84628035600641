
// 出差单详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
// 退改规则
import stipulates from "@/lib/data-service/haolv-default/consumer_flight_search_stipulates";
// 退改规则展示组件
import ChangClause from "@/page/admin/travel-book/flight/component/changClause";
import moment from 'moment';
import { getWeekDay } from "@/lib/utils/common";
export default {
  components: {
    ChangClause
  },
  data() {
    return{
      loading: false,
      evectionNo: '',
      // '审批类型：1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请',
      approveType: '',
      detail: {},
      getWeekDay,
      ruleText: [],
      exceedStatu: false
    }
  },
  computed: {
    currentStatus () {

    }
  },
  methods: {
    req_detail () {
      this.loading = true;
      // '审批类型：1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请',
      const approveType = Number(this.approveType);
      if (approveType === 1) {
        consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, pageType: 2}).then((res) => {
          const detail = res.datas;

          detail.disDate = moment(detail.endDate).diff(moment(detail.startDate), 'days') + '天';
          
          this.detail = res.datas;
          this.loading = false;
        });
      } else if (approveType === 2) {

      } else if (approveType === 3) {

      } else if (approveType === 4) {

      } else if (approveType === 5) {

      }
    },
    init () {
      const query = this.$route.query;
      this.evectionNo = query.evectionNo;
      this.approveType = query.approveType;
      this.approveType == 2 ? this.exceedStatu = true : false;
      this.req_detail()
    },
    
    backRule(flight, val) {
      this.ruleText = [];
      let data = {
        rqData: {
          arrCityPortCode: val.arrCityCode,
          cabinCode: val.cabinCode,
          depCityPortCode: val.depCityCode,
          depDate: val.depDate,
          flightNo: val.flightNo,
          flightProductType: val.flightProductType,
          flightType: flight.flightType,
          psgType: flight.psgType
        }
      };
      stipulates(data).then(res => {
        let refRule = res.rsData.refRule;
        let chaRule = res.rsData.chaRule;
          let refRuleTitArr = []
          let chaRuleTitArr = []
          let refRuleArr = []
          let chaRuleArr = []
          if (refRule && chaRule) {
              refRule = refRule.rules
              chaRule = chaRule.rules
              refRule.forEach(value => {
                  value.type = "refRule"
                  if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                      refRuleTitArr.push(value.ruleTime)
                      refRuleArr.push(value)
                  }
              })
              chaRule.forEach(value => {
                  value.type = "chaRule"
                  if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                      chaRuleTitArr.push(value.ruleTime)
                      chaRuleArr.push(value)
                  }
              })
              refRuleArr.sort((a, b) => {
                  return a.serial - b.serial
              })
              chaRuleArr.sort((a, b) => {
                  return a.serial - b.serial
              })
              // refRule.map(item => (item.type = "refRule"));
              // chaRule.map(item => (item.type = "chaRule"));
              this.ruleText = [refRuleArr, chaRuleArr];
          }
        this.ruleLoading = false;
        this.luggage = res.rsData.luggage;
      });
    }
  },
  created() {},
  mounted() {
    
  },
  activated() {
    this.init();
  },
  watch: {},
  filters: {
    
		format_time(val) {
			return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
		},
		format_class(val) {
			switch (val) {
				case 0:
					return "notstart";
					break;
				case 1:
					return "pending";
					break;
				case 2:
					return "success";
					break;
				case 3:
					return "fail";
					break;
				case 4:
					return "failure";
					break;
			}
		},
		format_status(val) {
			switch (val) {
				case 0:
					return "未开始";
					break;
				case 1:
					return "审批中";
					break;
				case 2:
					return "审批通过";
					break;
				case 3:
					return "驳回";
					break;
				case 4:
					return "已失效";
					break;
			}
		},
  }
};
